<template>
  <Dialog
    :value="isOpen"
    maxWidth="800"
    :title="$t('chef.signUpForm.profile')"
    :rightBtnText="$t('common.submit')"
    @right-btn-click="updateChefProfile"
    :leftBtnText="$t('common.cancel')"
    @left-btn-click="clearForm"
    :persistent="true"
    :loading="loading"
    :color="'info'"
  >
    <template>
      <v-container>
        <form ref="chefInfoForm" class="mx-lg-12">
          <ImageCropper
            :type="type"
            ref="cropper"
            :value="isImageCropperDialogOpen"
            :image="image"
            @upload-image="uploadImage"
            @cancel-crop="resetImageAndCloseCropPopUp"
          />
          <div
            v-if="
              status !== 'ACTIVE' ||
                (chef.images.length > 0 && !chef.images[0].includes('.'))
            "
          >
            <v-btn
              v-if="chef.images.filter(item => item === 'IMAGE').length < 1"
              class="white--text text-none mb-4"
              color="black"
              width="100%"
              :class="{ 'mobile-button': $vuetify.breakpoint.smAndDown }"
              @click="openUploadDialog('IMAGE')"
            >
              <v-icon small class="mr-1">fas fa-plus</v-icon>

              {{ $t("chef.uploadChefImage") }}
            </v-btn>
            <v-btn
              v-else
              class="white--text text-none mb-4"
              color="primary"
              width="100%"
              :class="{ 'mobile-button': $vuetify.breakpoint.smAndDown }"
              @click="confirmRemoveImage('IMAGE')"
            >
              <v-icon small class="mr-1">fas fa-minus</v-icon>
              {{ $t("chef.deleteChefImage") }}
            </v-btn>
          </div>

          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="fileSelected"
            accept="image/jpeg, image/png"
          />

          <v-dialog v-model="fileUploadingDialog" persistent width="300">
            <v-card color="primary" dark>
              <v-card-text class="pt-2 pb-2">
                {{
                  fileDeletingDialog && fileUploadingDialog
                    ? $t("common.deleting")
                    : $t("common.uploading")
                }}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>

          <div v-if="status !== 'ACTIVE'">
            <v-btn
              v-if="chef.images.filter(item => item === 'ID').length < 1"
              class="white--text text-none mb-4"
              color="black"
              width="100%"
              :class="{ 'mobile-button': $vuetify.breakpoint.smAndDown }"
              @click="openUploadDialog('ID')"
              ><v-icon small class="mr-1">fas fa-plus</v-icon>
              {{ $t("chef.uploadIdImage") }}
            </v-btn>
            <v-btn
              v-else
              class="white--text text-none mb-4"
              color="primary"
              width="100%"
              :class="{ 'mobile-button': $vuetify.breakpoint.smAndDown }"
              @click="confirmRemoveImage('ID')"
            >
              <v-icon small class="mr-1">fas fa-minus</v-icon>
              {{ $t("chef.deleteIdImage") }}
            </v-btn>
          </div>
          <v-sheet v-if="loading" :color="'grey lighten-4'">
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              type="image"
            ></v-skeleton-loader
          ></v-sheet>
          <v-carousel
            v-if="
              chef.images[0] !== 'ID' &&
                chef.images.length &&
                status === 'ACTIVE'
            "
            :show-arrows="false"
            :hide-delimiters="true"
            height="200"
            class="mb-4"
          >
            <v-carousel-item
              contain
              v-for="(img, index) in chef.images"
              :key="index"
              :src="img"
            >
              <v-container class="ma-0 pa-0" fluid>
                <v-row
                  justify="center"
                  align="center"
                  row
                  class="mt-0 pt-0 mx-0"
                >
                  <v-spacer></v-spacer>
                  <v-col cols="1" class="mx-lg-1 mx-4">
                    <v-btn
                      @click="confirmRemoveImage('IMAGE')"
                      color="#E0E0E0"
                      icon
                    >
                      <v-icon color="primary" medium>fas fa-trash</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
          <span class="caption">{{ $t("common.images") }}</span>
          <v-divider class="mb-8"></v-divider>

          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="chef.en.description"
            :error-messages="descriptionErrorsEN"
            maxLength="250"
            :label="$t('chef.signUpForm.descriptionEN')"
            required
            @input="$v.chef.en.description.$touch()"
          ></v-text-field>

          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="chef.ar.description"
            :error-messages="descriptionErrorsAR"
            maxLength="250"
            :label="$t('chef.signUpForm.descriptionAR')"
            required
            @input="$v.chef.ar.description.$touch()"
          ></v-text-field>

          <v-text-field
            :loading="loading"
            outlined
            dense
            v-model="chef.iban"
            :error-messages="ibanErrors"
            placeholder="SAXXXXXXXXXXXXXXXXXXXXXX"
            maxLength="24"
            :label="$t('chef.signUpForm.iban')"
            required
            @input="$v.chef.iban.$touch()"
            @keypress="onlyNumbersAndSA"
          ></v-text-field>

          <v-autocomplete
            :loading="loading"
            v-model="chef.bank"
            :items="banks"
            outlined
            dense
            required
            :error-messages="bankErrors"
            :label="$t('chef.banks')"
          >
          </v-autocomplete>
          <v-autocomplete
            :loading="loading"
            v-model="chef.nationality"
            :items="nationalites"
            outlined
            dense
            required
            :error-messages="nationalityErrors"
            :label="$t('chef.nationality')"
          >
          </v-autocomplete>
          <v-autocomplete
            :loading="loading"
            v-model="chef.languages"
            :items="languages"
            multiple
            outlined
            dense
            required
            :error-messages="languagesErrors"
            :label="$t('chef.languages')"
          >
          </v-autocomplete>
          <v-autocomplete
            :loading="loading"
            v-model="chef.categories"
            :items="categories"
            multiple
            small-chips
            outlined
            dense
            required
            :error-messages="categoriesErrors"
            :label="$t('chef.categories')"
          >
          </v-autocomplete>

          <v-select
            :loading="loading"
            v-model="chef.availability"
            :items="availability"
            small-chips
            multiple
            outlined
            dense
            required
            :error-messages="availabilityErrors"
            :label="$t('chef.availability')"
          >
          </v-select>

          <div class="d-flex flex-inline">
            <v-checkbox v-model="haveCulinary"> </v-checkbox>
            <v-card-subtitle class="mt-1">
              {{ this.$t("chef.haveCulinary") }}
            </v-card-subtitle>
          </div>
        </form>
      </v-container>
    </template>
    <ConfirmDialog
      @canceled="isConfirmDialogOpen = false"
      @confirmed="
        confirmDialogText === $t('chef.deleteChefImage')
          ? removeChefImage('IMAGE')
          : removeChefImage('ID')
      "
      :isOpen="isConfirmDialogOpen"
      :text="confirmDialogText"
    />
  </Dialog>
</template>
<script>
import Dialog from "@/components/Dialog";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ChefService from "@/services/api/ChefService";
import { mapFields } from "vuex-map-fields";
import ImageCropper from "@/components/ImageCropper";
import { mapMutations } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "ChefProfileForm",
  mixins: [validationMixin],
  components: {
    Dialog,
    ImageCropper,
    ConfirmDialog
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isConfirmDialogOpen: false,
      confirmDialogText: "",
      value: false,
      loading: false,
      type: "IMAGE",
      isImageCropperDialogOpen: false,
      fileUploadingDialog: false,
      fileDeletingDialog: false,
      image: "",

      languages: this.$t("lists.languages"),
      nationalites: this.$t("lists.nationalites"),
      banks: this.$t("lists.banks"),
      availability: this.$t("lists.days"),
      haveCulinary: false,
      chef: {
        en: {
          description: ""
        },
        ar: {
          description: ""
        },
        languages: [],
        iban: "SA",
        nationality: "",
        bank: "",
        categories: [],
        availability: [],
        images: []
      }
    };
  },
  watch: {
    isOpen(val) {
      this.categories = this.categories.filter(key => key.value !== "Menu");
      val ? this.getChefProfile() : "";
    }
  },
  computed: {
    ...mapFields("auth", ["id", "status"]),
    ...mapFields("main", ["categories"]),
    descriptionErrorsEN() {
      const errors = [];
      if (!this.$v.chef.en.description.$dirty) return errors;
      !this.$v.chef.en.description.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    descriptionErrorsAR() {
      const errors = [];
      if (!this.$v.chef.ar.description.$dirty) return errors;
      !this.$v.chef.ar.description.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    languagesErrors() {
      const errors = [];
      if (!this.$v.chef.languages.$dirty) return errors;
      !this.$v.chef.languages.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    availabilityErrors() {
      const errors = [];
      if (!this.$v.chef.availability.$dirty) return errors;
      !this.$v.chef.availability.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    categoriesErrors() {
      const errors = [];
      if (!this.$v.chef.categories.$dirty) return errors;
      !this.$v.chef.categories.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    nationalityErrors() {
      const errors = [];
      if (!this.$v.chef.nationality.$dirty) return errors;
      !this.$v.chef.nationality.required &&
        errors.push(this.$t("error.isRequired"));
      return errors;
    },
    bankErrors() {
      const errors = [];
      if (!this.$v.chef.bank.$dirty) return errors;
      !this.$v.chef.bank.required && errors.push(this.$t("error.isRequired"));
      return errors;
    },
    ibanErrors() {
      const errors = [];
      if (!this.$v.chef.iban.$dirty) return errors;
      !this.$v.chef.iban.required && errors.push(this.$t("error.isRequired"));
      !this.$v.chef.iban.minLength && errors.push(this.$t("error.minLength"));
      !this.$v.chef.iban.ibanValid && errors.push(this.$t("error.iban"));
      return errors;
    }
  },
  methods: {
    ...mapMutations("auth", ["addImage", "removeImage", "logout"]),
    onlyNumbersAndSA($event) {
      if (
        !new RegExp("^(S|A|[0-9])$").test(String.fromCharCode($event.which))
      ) {
        $event.preventDefault();
      }
    },
    async uploadImage(formData) {
      this.fileUploadingDialog = true;
      try {
        const uploadedImageType = await ChefService.uploadChefImage(formData);
        this.chef.images.push(uploadedImageType.type);
        this.resetImageAndCloseCropPopUp();
      } catch {
      } finally {
        this.isImageCropperDialogOpen = false;
        this.fileUploadingDialog = false;
      }
    },
    async updateChefProfile() {
      this.$v.chef.$touch();
      if (!this.$v.chef.$invalid) {
        if (
          this.chef.images.length === 2 ||
          (this.status === "ACTIVE" &&
            this.chef.images.length ===
              (this.chef.images.filter(img => img === "ID").length === 1
                ? 2
                : 1))
        ) {
          const formData = new FormData();
          formData.append("descriptionEN", this.chef.en.description);
          formData.append("descriptionAR", this.chef.ar.description);
          formData.append("languages", JSON.stringify(this.chef.languages));
          formData.append(
            "availability",
            JSON.stringify(this.chef.availability)
          );
          formData.append("categories", JSON.stringify(this.chef.categories));
          formData.append("iban", this.chef.iban);
          formData.append("nationality", this.chef.nationality);
          formData.append("bank", this.chef.bank);
          formData.append(
            "haveCulinary",
            this.haveCulinary === undefined ? false : this.haveCulinary
          );

          this.loading = true;
          try {
            await ChefService.updateChefProfile(formData);
            this.$root.$alert.success({
              message: this.$t("success.profile")
            });
            if (this.status === "VERIFIED") {
              this.logout();
              this.$router.push({ name: "home" });
            } else this.getChefProfile();
          } catch (err) {
          } finally {
            this.loading = false;
            this.clearForm();
          }
        } else {
          this.$root.$alert.error({
            message: this.$t("error.uploadImages")
          });
        }
      } else {
        this.$refs.chefInfoForm.scrollIntoView({ behavior: "smooth" });
      }
    },
    async getChefProfile() {
      this.loading = true;
      try {
        const chefProfile = await ChefService.getChefProfile(
          this.$route.params.chef
        );
        this.chef = chefProfile;
        this.haveCulinary = chefProfile.haveCulinary;
        this.status === "ACTIVE" && this.chef.images.length !== 1
          ? (this.chef.images = [
              process.env.VUE_APP_BACKEND_URL +
                "/images/" +
                this.chef.images.filter(img => img !== "ID")
            ])
          : "";
      } catch {
      } finally {
        this.loading = false;
      }
    },
    resetImageAndCloseCropPopUp() {
      this.image = "";
      this.$refs.fileInput.value = "";
      this.$refs.cropper.reset();
      this.isImageCropperDialogOpen = false;
    },
    openImageCropperByIcon(image) {
      this.image = image;
      this.isImageCropperDialogOpen = true;
    },
    fileSelected() {
      if (this.$refs.fileInput.files[0].size < 1000000) {
        const reader = new FileReader();
        reader.readAsDataURL(this.$refs.fileInput.files[0]);
        reader.onload = async () => {
          const image = new Image();
          image.src = reader.result;

          image.onload = () => {
            if (image.width < 500 || image.height < 500) {
              this.$root.$alert.error({
                title: `${this.$t("error.dimensions")} ${image.width} x ${
                  image.height
                } ${this.$t("error.dimensionsMustBe")}`
              });
            } else {
              this.image = reader.result;
              this.openCropPopUp();

              if (this.$refs.cropper) {
                this.$refs.cropper.replace(this.image);
              }
            }
          };
        };
      } else {
        this.$root.$alert.error({
          message: this.$t("error.imageSize")
        });
      }
    },
    openUploadDialog(imageType) {
      this.type = imageType;
      this.$refs.fileInput.click();
    },
    confirmRemoveImage(type) {
      this.confirmDialogText =
        type === "IMAGE"
          ? this.$t("chef.deleteChefImage")
          : this.$t("chef.deleteIdImage");
      this.isConfirmDialogOpen = true;
    },
    async removeChefImage(imageType) {
      const formData = new FormData();
      formData.append("imageType", imageType);

      this.fileUploadingDialog = true;
      this.fileDeletingDialog = true;
      try {
        await ChefService.removeChefImage(formData);
        this.chef.images = this.chef.images.filter(item => item !== imageType);
        this.getChefProfile();
      } catch {
      } finally {
        this.fileUploadingDialog = false;
        this.fileDeletingDialog = false;
        this.isConfirmDialogOpen = false;
      }
    },
    openCropPopUp() {
      this.isImageCropperDialogOpen = true;
    },
    clearForm() {
      this.$v.$reset();
      this.haveCulinary = false;
      this.chef = {
        en: {
          description: ""
        },
        ar: {
          description: ""
        },
        languages: [],
        iban: "",
        nationality: "",
        bank: "",
        categories: [],
        availability: [],
        images: []
      };
      this.$emit("close-popup");
    }
  },
  validations: {
    chef: {
      en: {
        description: { required, maxLength: maxLength(250) }
      },
      ar: {
        description: { required, maxLength: maxLength(250) }
      },
      iban: {
        required,
        maxLength: maxLength(24),
        minLength: minLength(24),
        ibanValid: function(value) {
          return /^SA[0-9]{22}$/.test(value);
        }
      },
      languages: { required },
      availability: { required },
      nationality: { required },
      bank: { required },
      categories: { required }
    }
  }
};
</script>
